$grid-columns: 12 !default;
$grid-column-width: 10rem !default;
$grid-gutter-width: 3rem !default;
$grid-container: auto !default;

$font-family-base: "Open Sans", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-size-root: 62.5% !default;
$font-size-base: 1.6rem !default;
//$font-family-base:
$line-height-base: 1.5 !default;

$form-field-border-color: #ccc !default;

$margin-bottom-base: 2.4rem !default;

$breakpoints: (
  xs: 0,
  sm: 544px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;

$susy: (
  container: $grid-container,
  container-position: center,
  columns: $grid-columns,
  column-width: $grid-column-width,
  gutters: $grid-gutter-width / $grid-column-width,
  gutter-position: inside-static,
  math: fluid
) !default;
