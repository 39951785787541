@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import "variables";
@import "sass/skin-and-bones";
@import "typebase";
@import "slick";

body {
  // border-top: 3px solid $red;
  font-weight: 400;
  box-sizing: border-box;
  font-size: 14px;
}

body.modal-open {
  overflow: hidden;
}

html {
  box-sizing: border-box;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

.container {
  max-width: $max-width;
  margin: 0 auto;
  padding: 0px 10px;
  position: relative;
  @include target-tablet {
    > main {
      padding: 0px 10px;
    }
  }
}

a {
  color: $red;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: $black;
  }
  &.underline {
    text-decoration: underline;
    color: $red;
  }
}

h1,
h2,
h3,
h4,
h5 {
  @include helveticaCondensedBold;
  text-transform: uppercase;
}

h3 {
  font-size: 20px;
  margin-bottom: 10px;
}

.right-aligned {
  text-align: right;
}

// LINK STYLINGS

.center-aligned-black {
  text-align: center;
  display: block;
  background-color: $grey;
  color: white;
  @include helveticaCondensedBold;
  padding: 10px;
  margin-top: 10px;
  font-size: 14px;
}

.row {
  margin-left: -10px;
  margin-right: -10px;
}

.column {
  padding-left: 10px;
  padding-right: 10px;
}

ul {
  list-style: none;
  padding: 0;
  // padding-left: 1em;
  list-style-type: disc !important;
  li {
    padding-left: 10px;
    // border-left: 2px solid #d40000;
    margin-bottom: 24px;
    > ul li {
      margin-bottom: 12px;
    }
  }
}

ol {
  li {
    padding-left: 10px;
    margin-bottom: 24px;
  }
  li > ul li {
    margin-bottom: 12px;
  }
  li > ol li {
    margin-bottom: 12px;
  }
}

ul[style] {
  padding-left: 1em;
  list-style-type: disc !important;
  li {
    padding: 0px;
    border-left: 0px;
    margin-bottom: 0px;
  }
}

img {
  max-width: 100%;
  height: auto !important;
}

.input,
.select,
.input_submit {
  display: block;
  width: 100%;
  padding: 12px 20px;
  border-radius: 3px;
  border: 0px;
  outline: none;
  margin-bottom: 20px;
  background: #f0f0f0;
}

input,
select,
textarea {
  @include breakpoint(medium-down) {
    font-size: 16px !important;
  }
}

select.select::-ms-expand {
  display: none;
  appearance: none;
}

.input_submit {
  width: auto;
  float: right;
  background-color: #232323;
  color: white;
  font-size: 20px;
  font-family: "HelveticaNeue-CondensedBold", "HelveticaNeueBoldCondensed",
    "HelveticaNeue-Bold-Condensed", "Helvetica Neue Bold Condensed",
    "HelveticaNeueBold", "HelveticaNeue-Bold", "Helvetica Neue Bold",
    "HelveticaNeue", "Helvetica Neue", "TeXGyreHerosCnBold", "Helvetica",
    "Tahoma", "Geneva", "Arial Narrow", "Arial", sans-serif;
  font-weight: 600;
  font-stretch: condensed;
  &:disabled {
    cursor: not-allowed;
  }
}

table.spec,
table.option {
  min-width: 100%;
  td,
  th {
    padding: 8px;
    min-width: 120px;
    p {
      margin: 0;
      padding: 0;
    }
  }
}

table.spec .caption th,
table.spec th.caption,
table.option .caption th,
table.option th.caption {
  background-color: #797c8e;
  text-transform: uppercase;
  @include helveticaCondensedBold;
  font-size: 18px;
}

table.spec .head th,
table.spec th.head,
table.option .head th,
table.option th.head {
  background-color: #8b8ea3;
  color: #fff;
  @include helveticaCondensedBold;
  font-size: 16px;
}

table.spec th {
  background-color: #e5e3d8;
  min-width: 100px;
}

table.spec .sub th,
table.spec th.sub {
  background-color: #f1efea;
}

table {
  tr:nth-child(odd) {
    background-color: white;
  }
  tr:nth-child(even) {
    background-color: #fff;
  }
  td,
  th {
    border: 1px solid white;
  }
}

table ul {
  padding-left: 1em;
  list-style: disc;
  li {
    padding: 0px;
    border-left: 0px;
    margin-bottom: 0px;
  }
}

video {
  @include breakpoint(medium-down) {
    width: 100%;
  }
}

a.button {
  font-family: $boldCondensed;
  background-color: $red;
  color: white;
  padding: 14px 18px;
  display: inline-block;
  font-size: 18px;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 300;
  @include roundedCorners;
  &:after {
    content: "\e903";
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "icomoon" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $grey;
    padding-left: 13px;
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
    font-size: 90%;
  }
  &:hover {
    color: white;
    background-color: $grey;
    &:after {
      color: $red;
    }
  }
}
a.button-light {
  background-color: #e60c19;
  text-transform: none;
  text-align: center;
  position: relative;
  min-width: 222px;
  padding-right: 38px;
  border-radius: 4px;
  &:after {
    color: #fff;
    position: absolute;
    top: 19px;
    right: 10px;
  }
}
.font-16px {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    font-size: 16px;
    line-height: 22px;
  }
}

.spanMobileDeskBlock {
  display: inline;
  @include breakpoint(medium) {
    display: block;
  }
}

.select {
  background: #f0f0f0;
  background-repeat: no-repeat;
  background-position: right;
  -webkit-appearance: none;
  -moz-appearance: none;
  color: #999;
  display: block;
  width: 100%;
  padding: 9px 20px;
  border-radius: 3px;
  border: 0px;
  outline: none;
  margin-bottom: 20px;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .select {
    color: #000;
  }
}

.input {
  display: block;
  width: 100%;
  padding: 9px 20px;
  border-radius: 3px;
  border: 0px;
  outline: none;
  margin-bottom: 20px;
  background: #f0f0f0;
}

.filter_listing__results {
}

.filter_listing__result {
  &:first-child {
    border-top: 4px solid black;
  }
}

.filter_listing__result_title {
  font-size: 20px;
  font-weight: 900;
  margin-top: 26px;
  margin-bottom: 22px;
}

.filter_listing__result_details {
  padding-bottom: 30px;
  border-bottom: 2px solid #f0f0f0;
}

.formWhere {
  width: 100%;
  background-color: $red;
  padding: 30px 25px 10px 25px;
}

.formWhereh {
  font-weight: 600;
  font-stretch: condensed;
  text-transform: uppercase;
  font-size: 20px;
  margin: 0;
  color: black;
  line-height: 1.4;
  padding-bottom: 23px;
  border-bottom: 4px solid black;
  margin: 22px 0px;
  clear: both;
}

#pnlAgilityStatusBar {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
  margin-bottom: 0 !important;
}

.loading-widget {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $white;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  .animate-spin {
    font-size: 24px;
    margin-bottom: 8px;
    animation: spin 1s linear infinite;
    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
}

// Global style from HomeOwners_Sidebar for HomeOwners components
#sidebar a.button {
  color: #333;
  background-color: transparent;
  border: 1px solid #333;
  line-height: 1;
  font-size: 1.4em;
  padding: 10px 14px;
  width: 100%;
}

#sidebar a.button.highlight {
  background-color: #d40000;
  color: white;
  border-color: #d40000;
}

#sidebar a.button small {
  margin-top: 5px;
  display: block;
  font-family:
    HelveticaNeue,
    Helvetica Neue,
    Helvetica,
    Arial,
    sans-serif;
  text-transform: initial;
  font-size: 0.7em;
  line-height: 1.2;
}

#sidebar a.button:after {
  content: none;
}

#body_left > div:first-child p {
  font-family:
    HelveticaNeue-CondensedBold,
    HelveticaNeueBoldCondensed,
    HelveticaNeue-Bold-Condensed,
    HelveticaNeueCondensed-CondensedRegular,
    Helvetica Neue Bold Condensed,
    HelveticaNeueBold,
    HelveticaNeue-Bold,
    Helvetica Neue Bold,
    HelveticaNeue,
    Helvetica Neue,
    TeXGyreHerosCnBold,
    Helvetica,
    Tahoma,
    Geneva,
    Arial Narrow,
    Arial,
    sans-serif;
  font-weight: 600;
  font-stretch: condensed;
  text-transform: uppercase;
  margin-bottom: 0 !important;
}

#body_left > div:first-child a {
  color: black !important;
}

.featherlight-content {
  vertical-align: top !important;
  top: 100px;
}

p.featherlight-inner {
  width: 500px;
}

.column-xs-1 a.button:after {
  display: none !important;
}

.column-xs-1 {
  padding-right: 0 !important;
}

.column-xs-8 {
  padding-left: 0 !important;
}

.lightbox_content {
  max-width: 500px;
  display: flex !important;
  align-items: flex-end !important;
}

.lightbox_content > div:nth-child(2) {
  white-space: nowrap;
  flex-grow: 1;
}

.lightbox_content > div:nth-child(2) a {
  margin-left: 10px;
}

._275X {
  height: 115px !important;
}

@media screen and (min-width: 992px) {
  .mobile_only {
    display: none !important;
  }
}

@include grid-col-list;
@include col-span-list;

.two-grey-columns .rich-text {
  .text-grey-lg {
    @include helveticaCondensedBold;
    color: #535353;
    font-size: 32px;
    margin-bottom: 4px;
    margin-top: 24px;
    line-height: 120%;
    @include breakpoint(small) {
      font-size: 25px;
      margin-top: 16px;
    }
  }
}

.grey-text {
  color: rgba(0, 0, 0, 0.8);
  font-size: 16px;
  line-height: 27.28px;
  @include breakpoint(medium-down) {
    font-size: 14px;
    font-weight: 300;
    line-height: 18.2px;
  }
}

.left-space-20-desk {
  @include breakpoint(large) {
    margin-left: 20px;
  }
}

//global class style used in rich text
.rx16 {
  font-family: $boldCondensed;
  font-weight: 600;
  font-stretch: condensed;
  font-size: 30px;
  border-left: 4px solid $red;
  padding: 0px;
  margin-top: 36px;
  text-transform: uppercase;
  padding-left: 18px;
  line-height: 1;
  padding-bottom: 10px;
  color: #232323;
  margin-bottom: 10px;
}

._3EGx {
  width: 100%;
  background-color: #d40000;
  padding: 0 25px;
}

.Qr-D {
  display: block;
  width: 100%;
  padding: 9px 20px;
  border-radius: 3px;
  border: 0;
  outline: none;
  margin-bottom: 20px;
  background: #f0f0f0;
  background-repeat: no-repeat;
  background-position: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  color: #999;
}

/* Richtext overrides */

.ol-override {
  padding-left: 18px;
  margin-top: 0;

  li {
    margin-bottom: 0;
  }
}

.button-group {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: fit-content;
  margin: auto;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 15px;
    max-width: 100%;
  }

  & > span {
    margin-bottom: 10px;
    width: 100%;

    @media screen and (min-width: 768px) {
      width: auto;
      margin-right: 3px;
      margin-bottom: 0;
    }

    a {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media screen and (min-width: 768px) {
        width: auto;
      }
    }
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.skip-to-content {
  position: absolute;
  left: -9999px;
  z-index: 999;
  padding: 1em;
  background-color: black;
  color: white;
  opacity: 0;
}

.skip-to-content:focus {
  left: 0%;
  transform: translateX(0%);
  opacity: 1;
}

.ais-Pagination-list {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  list-style: none;
}

.ais-Pagination-list li {
  list-style: none;
}

.ais-Hits-list {
  margin: 0 auto;
  padding: 0;
}

.ais-Hits-list li {
  list-style: none;
}

.ais-Hits-list li:empty {
  display: none;
}

.ais-SearchBox-form {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ais-SearchBox-form input {
      border: none;
        border-bottom: 2px solid black;
        font-size: 20px;
        font-style: italic;
        width: 100%;
        display: block;
        padding: 10px;
}

button.ais-SearchBox-submit,
button.ais-SearchBox-reset {
  width: 52px;
  height: 52px;
  border: none;
  background: white;
}

button.ais-SearchBox-submit svg,
button.ais-SearchBox-reset svg {
  width: 28px;
  height: 28px;
}

.cta {
  padding: 0 !important;
  background-color: transparent !important;
  color: #f00f00 !important;
}
.cta:after {
  color: #D40000 !important;
}
.button-group a.button {
  @media screen and (max-width: 768px) {
    margin-bottom: 16px;
    width: 100%;
  }
}
.mb0 {
  margin-bottom: 0;
}
.mt0 {
  margin-top: 0;
}