/* Base Setup */
@import "sass/variables";

html {
    font-family: "Helvetica","Arial",sans-serif;
    font-size: $baseFontSize / 16 * 100%;
    font-weight: 300;
    // Make everything look a little nicer in webkit
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: auto;
    -moz-text-size-adjust: auto;
    -ms-text-size-adjust: auto;
    -o-text-size-adjust: auto;
    text-size-adjust: auto;

}
/* Copy & Lists */

p {
    line-height: $leading;
    margin-bottom: 1em;
}
ul,
ol {
    margin-top: $leading;
    margin-bottom: $leading;
    li {
        line-height: $leading;
    }
    ul,
    ol {
        margin-top: 0;
        margin-bottom: 0;
    }
}
blockquote {
    line-height: $leading;
    margin-top: $leading;
    margin-bottom: $leading;
}
/* Headings */

h1,
h2,
h3,
h4,
h5,
h6 {
    /* Change heading typefaces here */
    margin-top: $leading;
    margin-bottom: 0;
    line-height: $leading;
    font-weight: bold;
    letter-spacing: 0px;
}
h1 {
    font-size: 3 * $scale * 1rem;
    line-height: 3 * $leading;
    margin-top: 2 * $leading;
}
h2 {
    font-size: 2 * $scale * 1rem;
    line-height: 2 * $leading;
    margin-top: 2 * $leading;
}
h3 {
    font-size: 1 * $scale * 1rem;
}
h4 {
    font-size: $scale * 0.8 * 1rem;
}
h5 {
    font-size: $scale * 0.6 * 1rem;
}
h6 {
    font-size: $scale * 0.5 * 1rem;
    margin-bottom: 10px;
}

body {
    font-family: "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
/* Leading paragraph text */

.lead {
    font-size: $scale * 1rem;
}
/* Hug a the block above you */

.hug {
    margin-top: 0;
}