@import "sass/variables";
@import "sass/mixins";
@import "mixins";

// $max-width: 1020px;
$max-width: 1440px;
$max-width-nav: 1380px;
$grid-gutter-width: 4rem;
$margin-bottom-base: 2rem;


// Primary Colours
// $red : #d40000;
$red: #f00f00;
$black : #000000;
$grey : #232323;
$lightGrey : #e9e9e9;
$white: #fff;

$baseFontSize:14;
$baseLineHeight:1.5;
$leading:$baseLineHeight * 1rem;
$scale:1.414;


@font-face {
  font-family: 'HelveticaNeueCondensed-CondensedRegular';
  src:  url('https://cdn.agilitycms.com/mesca/helveticaneuecondensed-condensedregular.eot');
  src:  url('https://cdn.agilitycms.com/mesca/helveticaneuecondensed-condensedregular.eot?#iefix') format('embedded-opentype'),
        url('https://cdn.agilitycms.com/mesca/helveticaneuecondensed-condensedregular.woff') format('woff'),
        url('https://cdn.agilitycms.com/mesca/helveticaneuecondensed-condensedregular.ttf')  format('truetype');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'HelveticaNeueCondensedBold';
  src:  url('https://cdn.agilitycms.com/mesca/helveticaneue-condensedbold.eot');
  src:  url('https://cdn.agilitycms.com/mesca/helveticaneue-condensedbold.eot?#iefix') format('embedded-opentype'),
        url('https://cdn.agilitycms.com/mesca/helveticaneue-condensedbold.woff') format('woff'),
        url('https://cdn.agilitycms.com/mesca/helveticaneue-condensedbold.ttf')  format('truetype');
}

$boldCondensed: "HelveticaNeue-CondensedBold", "HelveticaNeueBoldCondensed", "HelveticaNeue-Bold-Condensed", "HelveticaNeueCondensed-CondensedRegular", "Helvetica Neue Bold Condensed", "HelveticaNeueBold", "HelveticaNeue-Bold", "Helvetica Neue Bold", "HelveticaNeue", "Helvetica Neue", 'TeXGyreHerosCnBold', "Helvetica", "Tahoma", "Geneva", "Arial Narrow", "Arial", sans-serif;

@font-face {
    font-family: 'icomoon';
    src:    url('https://cdn.agilitycms.com/mesca/icomoon.eot?yzo55l');
    src:    url('https://cdn.agilitycms.com/mesca/icomoon.eot?yzo55l#iefix') format('embedded-opentype'),
        url('https://cdn.agilitycms.com/mesca/icomoon.ttf?yzo55l') format('truetype'),
        url('https://cdn.agilitycms.com/mesca/icomoon.woff?yzo55l') format('woff'),
        url('https://cdn.agilitycms.com/mesca/icomoon.svg?yzo55l#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-left:before {
    content: "\e900";
}
.icon-right:before {
    content: "\e901";
}
.icon-search:before {
    content: "\e902";
}
.icon-arrow:before {
    content: "\e903";
}
.icon-download:before {
    content: "\e904";
}
.icon-link:before {
    content: "\e905";
    font-size: 150%;
    color: $red;
}
.icon-globe:before {
    content: "\e906";
}
