html {
  font-size: $font-size-root;
}

body {
  font-size: $font-size-base;
  line-height: $line-height-base;
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: $margin-bottom-base;
  font-weight: 300;
}

h1 {
  font-size: 4.0rem;
  line-height: 1.2;
  letter-spacing: -.1rem;
}

h2 {
  font-size: 3.6rem;
  line-height: 1.25;
  letter-spacing: -.1rem;
}

h3 {
  font-size: 3.0rem;
  line-height: 1.3;
  letter-spacing: -.1rem;
}

h4 {
  font-size: 2.4rem;
  line-height: 1.35;
  letter-spacing: -.08rem;
}

h5 {
  font-size: 1.8rem;
  line-height: $line-height-base;
  letter-spacing: -.05rem;
}

h6 {
  font-size: 1.6rem;
  line-height: $line-height-base;
  letter-spacing: 0;
}
