// Simple Breakpoints
@mixin breakpoint($point) {
  @if $point == large {
    @media (min-width: 64.375em) { @content; }
  }
  @else if $point == medium {
    @media (min-width: 38em) { @content; }
  }
  @else if $point == medium-down {
    @media (max-width: 64.374em) { @content; }
  }
  @else if $point == small {
    @media (max-width: 37.98em)  { @content; }
  }
}

// Targetted Breakpoints
@mixin target-mobile {
  @media (max-width: 37.5em) { @content; }
}

@mixin target-tablet() {
  @media (min-width: 37.501em) and (max-width: 64.374em) { @content; }
}

@mixin target-tablet-down() {
  @media (max-width: 64.374em) { @content; }
}

@mixin target-desktop() {
  @media (min-width: 64.375em) { @content; }
}

@mixin roundedCorners() {
  border-radius: 2px;
}

// Retina
@mixin image-2x($image, $width, $height) {
  @media (min--moz-device-pixel-ratio: 1.3),
         (-o-min-device-pixel-ratio: 2.6/2),
         (-webkit-min-device-pixel-ratio: 1.3),
         (min-device-pixel-ratio: 1.3),
         (min-resolution: 1.3dppx) {
    /* on retina, use image that's scaled by 2 */
    background-image: url($image);
    background-size: $width $height;
  }
}

// Clearfix
@mixin clearfix() {
    &:before,
    &:after {
        content: "";
        display: table;
        table-layout: fixed;
    }
    &:after {
        clear: both;
    }
}

// Box Sizing
@mixin box-sizing($box-model) {
  -webkit-box-sizing: $box-model; // Safari <= 5
     -moz-box-sizing: $box-model; // Firefox <= 19
          box-sizing: $box-model;
}

// Opacity For IE 8
@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

// Center align horizontally
@mixin center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

// Vertical Alignment
@mixin vertical-align {
  display: flex;
  display: -ms-flex;
  flex-direction: column;
  justify-content: center;
}

@mixin heleveticaStandard {
    font-family: "Helvetica","Arial",sans-serif;
}

@mixin helveticaCondensed {
  font-family: 'HelveticaNeueCondensed-CondensedRegular', sans-serif !important;
  font-stretch:condensed;
}

@mixin helveticaCondensedBold {
  font-family: "HelveticaNeue-CondensedBold", "HelveticaNeueBoldCondensed", "HelveticaNeue-Bold-Condensed", "Helvetica Neue Bold Condensed", 'HelveticaNeueCondensed-CondensedRegular', "HelveticaNeueBold", "HelveticaNeue-Bold", "Helvetica Neue Bold", "HelveticaNeue", "Helvetica Neue", 'TeXGyreHerosCnBold', "Helvetica", "Tahoma", "Geneva", "Arial Narrow", "Arial", sans-serif; font-weight:600; font-stretch:condensed;
}

$columns: 12;
@mixin grid-col-list {
  @for $i from 1 through $columns {
      .grid-cols-#{$i} { 
        grid-template-columns: repeat(#{$i}, 1fr);
      }
  }
}

@mixin col-span-list {
  @for $i from 1 through $columns {
      .col-span-#{$i} { 
        grid-column-start: span #{$i}; 
      }
  }
}
