.row {
  @include container;
  width: auto;
  margin-left: -$grid-gutter-width / 2;
  margin-right: -$grid-gutter-width / 2;
  display: flex;
  flex-flow: row wrap;
}

.row-center {
  align-items: center;
}

.row-bottom {
  align-items: flex-end;
}

.column {
  @include span($grid-columns);
  max-width: span($grid-columns);
  flex-basis: span($grid-columns);
}

.column-middle {
  align-self: center;
}

.column-bottom {
  align-self: flex-end;
}

.column-center {
    margin-left: auto;
    margin-right: auto;
}

.column-right {
    margin-right: 0;
    margin-left: auto;
}

@each $name, $start in $breakpoints {
  @include breakpoint-up($name) {
    @for $i from 1 through susy-get(columns) {
      .column-#{$name}-#{$i} {
        width: span($i);
        max-width: span($i);
        flex-basis: span($i);
      }
    }

    .column-#{$name}-25p {
      width: span(25%);
      max-width: span(25%);
      flex-basis: 25%;
    }

    .column-#{$name}-50p {
      width: span(50%);
      max-width: span(50%);
      flex-basis: 50%;
    }

    .column-#{$name}-33p {
      width: span(33.33333333%);
      max-width: span(33.33333333%);
      flex-basis: 33.33333333%;
    }
    
    .column-#{$name}-66p {
      width: span(66.66666667%);
      max-width: span(66.66666667%);
      flex-basis: 66.66666667%;
    }

    .column-#{$name}-75p {
      width: span(75%);
      max-width: span(75%);
      flex-basis: 75%;
    }
    
    .column-#{$name}-100p {
      width: span(100%);
      max-width: span(100%);
      flex-basis: 100%;
    }
    
    .column-#{$name}-middle {
      align-self: center;
    }

    .column-#{$name}-bottom {
      align-self: flex-end;
    }

    .column-#{$name}-center {
        margin-left: auto;
        margin-right: auto;
    }

    .column-#{$name}-right {
        margin-right: 0;
        margin-left: auto;
    }
  }
}
